import React from "react";
import {
  //  Link,
  graphql
} from "gatsby";

import { MetaData } from "~/components/meta";
import Layout from "~/components/Layout";

type Props = {} & any;

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 */
const Post = ({ data, location }: Props) => {
  const post = data.ghostPost;

  return (
    <>
      <MetaData data={data} location={location} type="article" />
      <Layout location={location}>
        <div className="mx-auto" style={{ maxWidth: 720 }}>
          <article className="content">
            {post.feature_image ? (
              <figure className="post-feature-image mb-6">
                <img src={post.feature_image} alt={post.title} />
              </figure>
            ) : null}
            <section className="post__body">
              <h1 className="text-4xl font-bold mb-4">{post.title}</h1>

              {/* The main post content */}
              <section
                className="text-lg leading-relaxed"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </section>
          </article>
        </div>
      </Layout>
    </>
  );
};

export default Post;

export const pageQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
  }
`;
